.mdb-rating {

  .rate-popover {
    color: #808080;
  }

  .live {
    color: #000;
  }

  .oneStar {
    color: #44370f;
  }

  .twoStars {
    color: #96781e;
  }

  .threeStars {
    color: #e2b52e;
  }

  .fourStars {
    color: #f1ba12;
  }

  .fiveStars {
    color: #f3cb06;
  }

  .amber-text {
    color: #ffc107;
  }

}
