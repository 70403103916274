// Your custom styles
@import '../../../node_modules/hover.css/scss/hover.scss';

html {
  font-size: 14px;
  height: 100%;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
body {
  font-size: 1rem;
  font-family: $font-family-base;
  text-align: left;
  color: $primary-dark;
  margin: 0;
  @include rtl(font-family, $font-family-arabic);
  @include rtl(text-align, right);
}
// Fixing fixed footer
// #root {
//   position: relative;
//   min-height: 100vh;
//   padding-bottom: 386px;
//   @include media-breakpoint-down(md) {
//     padding-bottom: 546px;
//   }
//   @include media-breakpoint-down(sm) {
//     padding-bottom: 661px;
//   }
// }
p {
  font-size: 1rem;
}
//HEADER===============================================================
//GENERAL===============================================================
.btn {
  border-radius: 8px;
  box-shadow: none;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);
  font-size: 1rem;
  text-transform: initial;
}
.sec-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.main-padding {
  padding-top: 8rem !important;
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 24 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  align-items: center;
}

.loader-layout {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000;
  opacity: 0.5;
}

.bg-light {
  background-color: $bg-gray-light !important;
}
.bg-dark {
  background-color: $bg-dark !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.flex-column {
  flex-direction: column;
}
.height-auto {
  height: auto !important;
}
.height-100 {
  height: 100% !important;
}
.text-normal {
  font-family: $font-family-base !important;
  @include rtl(font-family, $font-family-arabic !important);
}
.text-bold {
  font-family: $font-family-bold !important;
  @include rtl(font-family, $font-family-arabic-bold !important);
}
.mb-2rem {
  margin-bottom: 2rem !important;
}
//TOASTER========================================================================================
.react-toast-notifications__container {
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  right: 0px;
  padding: 0 !important;
  width: 100% !important;
  position: absolute !important;
  top: 82px !important;
  @include media-breakpoint-down(md) {
    top: 72px;
  }
  .css-wo4ljk-ToastElement {
    transition: none !important;
    transform: none !important;
  }
  .css-h0gpo1-ToastElement {
    transition: none !important;
  }
  .toasterCustom {
    background-color: $success-color !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: #fff !important;
    display: flex !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    min-height: 48px !important;
    transition: none !important;
    transform: none !important;
    .react-toast-notifications__toast__icon-wrapper {
      display: none !important;
    }
    .react-toast-notifications__toast__content {
      color: white !important;
      font-family: $font-family-base !important;
      @include rtl(font-family, $font-family-arabic !important);
      padding: 1rem 1.25rem !important;
      text-align: center !important;
      min-height: 48px !important;
    }
    .react-toast-notifications__toast__dismiss-button {
      padding: 0.8rem !important;
    }
  }
}
//End==TOASTER========================================================================================

:focus {
  outline: transparent auto 0;
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0;
}
.text-red {
  color: $msg-error-color !important;
}
.text-blue {
  color: $blue-color !important;
}
.text-green {
  color: $success-color !important;
}
.text-gray,
.text-muted {
  color: $text-gray !important;
}
.pl-4rem {
  padding-left: 4rem !important;
  @include rtl(padding-right, 4rem !important);
  @include rtl(padding-left, 0 !important);
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid $bg-dark;
}
a.link-red,
button.link-red,
.link-red {
  color: $msg-error-color !important;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);
}
a.link-red:hover,
.link-red:hover,
button.link-red:hover {
  text-decoration: underline;
  color: $msg-error-color !important;
}
a.link-blue,
.link-blue,
button.link-blue {
  color: $blue-color !important;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);
}
a.link-blue:hover,
.link-blue:hover,
button.link-blue:hover {
  text-decoration: underline;
  color: #43869a !important;
}
a.link-grey,
.link-grey,
button.link-grey {
  color: $primary-dark;
  font-family: $font-family-base;
  @include rtl(font-family, $font-family-arabic);
}
a.link-grey:hover,
.link-grey:hover,
button.link-grey:hover {
  text-decoration: underline;
}
.link-text {
  color: #191820;
  text-align: center;
  margin-bottom: 0.5rem;
}
.h1-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 3rem;
  margin-bottom: 1rem;
}
.h2-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.h3-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 2rem;
  margin-bottom: 1rem;
}
.h4-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.circle-center-img {
  text-align: center;
  margin-bottom: 2rem;
}
.mb-2rem {
  margin-bottom: 2rem !important;
}
//BUTTONS============================================
.btn {
  border-radius: 24px;
  color: $primary-dark !important;
}
.btn-primary {
  background-color: $primary-color;
  color: $primary-dark;
}
.btn-outline-primary {
  background-color: $primary-pressed !important;
  box-shadow: none;
  color: $primary-dark !important;
  border-color: $primary-pressed !important;
}
.btn-primary:hover,
.btn-outline-primary:hover {
  color: $primary-dark !important;
  background-color: $primary-hover !important;
  border-color: $primary-hover !important;
}
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $primary-pressed !important;
  box-shadow: none;
  color: $primary-dark !important;
}
.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  color: $primary-dark !important;
}
.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $primary-pressed !important;
  box-shadow: none;
  color: $primary-dark !important;
  border-color: $primary-pressed !important;
}
.btn-outline-primary:not([disabled]):not(.disabled):active:focus,
.btn-outline-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
  color: $primary-dark !important;
  border-color: $primary-pressed !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:active:focus,
.btn-outline-primary.active {
  color: $primary-dark !important;
}
.btn.btn-block {
  margin: 0;
}
.btn:hover,
.btn:focus,
.btn:active {
  outline: 0;
  box-shadow: none;
}

.btn-white:not([disabled]):not(.disabled):active,
.btn-white:not([disabled]):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: $text-gray;
  background: $bg-dark;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}
.btn-white:hover {
  background: $bg-dark !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  color: $text-gray;
}

img {
  max-width: 100% !important;
}
.btn-rounded {
  border-radius: 10em;
}
.btn-join-us {
  border-radius: 50px;
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadce0;
  padding-top: 7px;
  padding-bottom: 7px;
  font-family: $font-family-medium;
  @include rtl(font-family, $font-family-arabic-Medium);

  &:hover {
    background: rgba(66, 133, 244, 0.04) !important;
    box-shadow: none !important;
  }
}
.footer-btn {
  border-radius: 8px;
}
//CashbackStores======================================
.cashbackStores-section {
  background: $white;
}
.sub-title-light {
  font-size: 1.6rem;
  font-family: $font-family-base;
  @include rtl(font-family, $font-family-arabic);
}
.sub-title-div {
  display: block;
  margin: auto;
  text-align: center;
}
.title {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 2rem;
  padding: 1rem 0;
  text-align: center;
}
.sub-title {
  font-size: 2.3rem;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  position: relative;
  padding: 1rem 0;
  margin-bottom: 4rem;
  display: inline-block;
  text-align: left;
  &.title-full {
    display: block;
    @include rtl(text-align, right);
  }
}
.sub-title::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 0.15rem;
  border-radius: 2rem;
  background: #000;
  bottom: 0;
  left: 0;
  right: auto;
  @include rtl(right, 0);
  @include rtl(left, auto);
}
.page-title {
  font-family: $font-family-base;
  @include rtl(font-family, $font-family-arabic);
  font-size: 2rem;
  color: $primary-dark;
}
.cashback-card {
  padding: 0 2rem 4rem 2rem;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .card-text {
    margin-bottom: 1.5rem;
  }
}
.cashback-card-img {
  width: auto;
  max-height: 100px;
  height: 100px;
  overflow: hidden;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $bg-light;
  background-color: transparent;
  margin-bottom: 1.5rem;
}

.seeAllStors-text {
  text-align: center;
  padding-top: 2rem;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  a {
    text-align: center;
    color: $primary-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 1rem;
    }
  }
  a:hover {
    opacity: 0.8;
  }
}
//CASHBACK CATEGORY===============================================
.cashbackCategories-section {
  background-color: $bg-light;
}
//CARDS===========================================
.card-title {
  color: $primary-dark;
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  font-size: 1.375rem;
  margin-bottom: 1.5rem;
}
.coming-soon-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);
  font-size: 1.375rem;
  margin-bottom: 0;
  padding-top: 14px;
  padding-bottom: 14px;
}
.cashback-text {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);

  color: $msg-error-color;
  font-size: 1.375rem;
  margin-bottom: 0;
}
.card .card-body .card-text,
.card .card-text {
  color: $text-gray;
}
.text-gray {
  color: $text-gray;
  font-size: 1.375rem;
  font-family: $font-family-base;
  @include rtl(font-family, $font-family-arabic);
  margin-bottom: 0;
}

//adsToStores-section=================================================
.adsToStores-section {
  background-color: $primary-color;
  background-image: url(../img/in-store_img.png);
  background-position: right bottom;
  background-repeat: no-repeat;
}
///////////////////////////VALIDATION=======================
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 0;
  border-bottom: 1px solid $msg-error-color;
  padding-right: calc(1.5em + 0.75rem);
  @include rtl(padding-right, 0);
  @include rtl(padding-left, calc(1.5em + 0.75rem));
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: $msg-error-color;
  box-shadow: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border: 0;
  border-bottom: 1px solid $success-color;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: $success-color;
  box-shadow: none;
}
//INPUTS and BUTTONS=====================================

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.375rem 0;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0000;
  border-bottom: 1px solid $bg-dark;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-label {
  font-family: $font-family-bold;
  @include rtl(font-family, $font-family-arabic-bold);
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0000;
  border-bottom: 1px solid $bg-dark;
  outline: 0;
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef4f;
  opacity: 1;
}
.btn.disabled,
.btn:disabled {
  background-color: $bg-dark !important;
  color: $primary-dark;
}
input.is-invalid {
  border-bottom: 1px solid red;
}
.error.error-message,
.invalid-feedback {
  color: $msg-error-color;
  text-align: left;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  @include rtl(text-align, right);
}

.success.success-message {
  color: $success-color;
  text-align: left;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  @include rtl(text-align, right);
}

textarea.form-control {
  height: auto;
  border: 1px solid $bg-dark;
  border-radius: 24px;
  padding: 1rem;
}
textarea.form-control:focus {
  border: 1px solid $bg-dark;
  outline: 0;
  box-shadow: none;
}
.custom-checkbox {
  padding-left: 2.1rem;
  @include rtl(padding-right, 2.1rem);
  @include rtl(padding-left, 0);
  .custom-control-input {
    left: 6px;
    right: auto;
    @include rtl(right, 6px);
    @include rtl(left, auto);
    top: 3px;
  }
  .custom-control-label {
    line-height: 1.7rem;
  }
}

//BREADCRUMBS=================================================================
.breadcrumb {
  background-color: #0000;
  padding: 0.75rem 0;
}
.breadcrumb-item > a {
  color: $primary-dark;
}
.breadcrumb-item > a:hover {
  color: $primary-dark;
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: $primary-dark;
  content: '>';
}
.breadcrumb-item.active {
  color: $primary-dark;
}
//END = BREADCRUMBS=================================================================
//Tabs==============================================================================
.tabs {
  padding: 2rem 0;
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-tabs .nav-link {
    border: 0;
    border-radius: 0;
    width: 50%;
    color: $primary-dark;
    border-bottom: 1.5px solid $bg-dark;
    text-align: center;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $blue-color;
    background-color: #fff;
    border-bottom: 1.5px solid $blue-color;
    font-family: $font-family-bold;
    @include rtl(font-family, $font-family-arabic-bold);
  }
  .tab-content > .tab-pane {
    padding: 1rem 0;
  }
}
///////////////////////////////////
.empty-results {
  text-align: center;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    margin-bottom: 2rem;
  }
}

//FOOTER=======================
// footer.footer {
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   width: 100%;
// }
// WALLET
.merge-link,
.merge-link:hover {
  text-decoration: underline;
  color: $primary-dark;
  margin-top: 25px;
  display: inline-block;
  font-size: 16px;
}
// Firefox Hacks===============================
@-moz-document url-prefix() {
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:active,
  .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 2px solid #43869a;
  }
  select {
    -moz-appearance: none;
  }
  .step2 .pincode-input-container .pincode-input-text {
    min-width: 40px;
  }
}

//ReCaptcha
form iframe {
  transform: scale(0.96);
  transform-origin: left;
}
@import '../scss/arabic_styles';
