//ARABIC STYLES Overriding Bootstrap RTL=======================
.mr-2, .mx-2 {
    margin-right: .5rem;
    @include rtl( margin-right,0 !important);
    @include rtl( margin-left,.5rem);
  }
  .mr-3, .mx-3 {
    margin-right: 1rem;
    @include rtl( margin-right,0 !important);
    @include rtl( margin-left,1rem  !important);
  }
  .img-rotate-ar{
    @include rtl( transform, rotate(180deg));
  }
  .text-right {
    text-align: right!important;
    @include rtl(  text-align, left !important);
  }
  .ml-auto, .mx-auto {
    margin-left:auto;
    @include rtl( margin-right,auto  !important);
    @include rtl( margin-left,0  !important);
  }
  .dir-ltr-ar{
    @include rtl( direction,ltr);
  }
  .dir-rtl-ar{
    @include rtl( direction,rtl);
  }
  @media (min-width: 768px){
    .offset-md-3 {
      margin-left: 25%;
      @include rtl( margin-right, 25%);
      @include rtl( margin-left, auto);
    }
    .offset-md-1 {
      margin-left: 8.333333%;
      @include rtl( margin-right,  8.333333%);
      @include rtl( margin-left, auto);
  }
  .offset-md-2 {
    margin-left: 16.666667%;
    @include rtl( margin-right, 16.666667%);
    @include rtl( margin-left, auto);
  }
  }
  @media (min-width: 992px){
    .offset-lg-4 {
        margin-left: 33.333333%;
        @include rtl( margin-right, 33.333333%);
        @include rtl( margin-left, auto);
    }
    .offset-lg-3 {
      margin-left: 25%;
      @include rtl( margin-right, 25%);
      @include rtl( margin-left, auto);
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
    @include rtl( margin-right, 8.333333%);
    @include rtl( margin-left, auto);
  }
    }
