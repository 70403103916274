// Your custom variables
//COLORS
$primary-color: #fae159;
$primary-hover: #f5cf01;
$primary-pressed: #fff5bd;

$primary-dark: #222d34;
$success-color: #48c564;
$msg-error-color: #ff585f;
$blue-color: #86d1d6;

$bg-light: #f8f8f8;
$bg-gray-light: #f9f9f9;
$text-gray: #929297;
$text-gray-blue: #718897;
$bg-dark: #e2e2e2;
$btn-light-color: #fefefe;
$blue-gray-color: #dce5eb;
$overlay-color: rgba(34, 45, 52, 0.5);
$grayscale200: #F7F9FA;

//FONTS
$font-family-base: "font-family-base";
$font-family-light: "font-family-light";
$font-family-bold: "font-family-bold";
$font-family-medium: "font-family-medium";
// $font-family-Italic: "font-family-Italic";

//RTL
$font-family-arabic: "AR_Regular";
$font-family-arabic-Medium: "AR_Medium";
$font-family-arabic-bold: "AR_Bold";
