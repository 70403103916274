//Fonts
@font-face {
  font-family: 'font-family-base';
  src: url('../font/HankenGrotesk/HankenGrotesk-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../font/HankenGrotesk/HankenGrotesk-Regular.woff2') format('woff2'),
    url('../font/HankenGrotesk/HankenGrotesk-Regular.woff') format('woff'),
    url('../font/HankenGrotesk/HankenGrotesk-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'font-family-light';
  src: url('../font/HankenGrotesk/HankenGrotesk-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../font/HankenGrotesk/HankenGrotesk-Light.woff2') format('woff2'),
    url('../font/HankenGrotesk/HankenGrotesk-Light.woff') format('woff'),
    url('../font/HankenGrotesk/HankenGrotesk-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'font-family-medium';
  src: url('../font/HankenGrotesk/HankenGrotesk-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../font/HankenGrotesk/HankenGrotesk-Medium.woff2') format('woff2'),
    url('../font/HankenGrotesk/HankenGrotesk-Medium.woff') format('woff'),
    url('../font/HankenGrotesk/HankenGrotesk-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'font-family-bold';
  src: url('../font/HankenGrotesk/HankenGrotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../font/HankenGrotesk/HankenGrotesk-Bold.woff2') format('woff2'),
    url('../font/HankenGrotesk/HankenGrotesk-Bold.woff') format('woff'),
    url('../font/HankenGrotesk/HankenGrotesk-Bold.ttf') format('truetype');
}
//Arabic Fonts
@font-face {
  font-family: 'AR_Regular';
  src: url('../font/cairo/Cairo-Regular.ttf');
}
@font-face {
  font-family: 'AR_Medium';
  src: url('../font/cairo/Cairo-SemiBold.ttf');
}
@font-face {
  font-family: 'AR_Bold';
  src: url('../font/cairo/Cairo-Bold.ttf');
}
